<template>
  <div class="add-room">
    <h1 class="title">{{`${$t('Building')} ${building.buildingName}`}}</h1>
    <h2 class="subtitle">{{$t('New Room')}}</h2>
    <ValidationObserver ref="validator">
      <form class="add-room-form" @submit.prevent>
        <b-row>
          <b-col cols="4">
            <RFValidation rules="required" name="Name">
              <RFInput label="Name" v-model="room.name"/>
            </RFValidation>
          </b-col>
          <b-col cols="4">
            <RFValidation rules="required" name="Description">
              <RFInput label="Description" v-model="room.description"/>
            </RFValidation>
          </b-col>
          <b-col cols="4">
            <RFValidation rules="required" name="Room Type">
              <RFSelect top-label="Type" v-model="room.typeId" @input="room.typeId = $event" :list="accommodationTypeList"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation rules="required" name="Square Meters">
              <RFInput label="Square Meters" v-model="room.sqMeters" is-numeric/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Number Of Person">
              <RFInput label="Number Of Person" type="number" :min="1" v-model="room.numOfPerson"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Num Of Bathrooms">
              <RFInput label="Num Of Bathrooms" type="number" :min="1" v-model="room.numOfBathrooms"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Floor" v-model="room.floor"/>
          </b-col>
          <b-col>
            <RFInput label="Min Stay Month" type="number" :min="1" v-model="room.minStayMonth"/>
          </b-col>
          <b-col>
            <RFInput label="Business Unit" v-model="room.businessUnit"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Admin Comment" v-model="room.adminComment" :value="room.adminComment"/>
          </b-col>
          <b-col>
            <RFInputBeds v-model="room.beds" />
          </b-col>
          <b-col>
            <RFSelect top-label="Bathroom type" v-model="room.bathroomType" :list="bathroomTypeList"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="auto">
            <RFCheckbox class="mt-4" label="Use contract template with private owner" v-model="room.ownerContractTemplate" />
          </b-col>
        </b-row>

        <h4 class="admin-subtitle mt-5">{{$t('Amounts')}}</h4>

        <b-row>
          <b-col>
            <RFValidation rules="required" name="Monthly Amount">
              <RFCurrencyInput label="Monthly Amount" v-model="room.monthlyAmount"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Deposit Amount">
              <RFCurrencyInput label="Deposit Amount" v-model="room.depositAmount"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFCurrencyInput label="Service Fees" v-model="room.serviceFees" />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <RFValidation rules="required" name="Service Amount">
              <RFCurrencyInput label="Service Amount" v-model="room.serviceAmount"/>
            </RFValidation>
          </b-col>
          <b-col cols="auto">
            <RFCheckbox class="mt-4" label="Pay only with bank transfer" v-model="room.bankTransferMonthlyPayments" />
          </b-col>
        </b-row>

        <h4 class="admin-subtitle mt-5">{{$t('Cadastral Data')}}</h4>

        <b-row>
          <b-col>
            <RFInput label="Category" v-model="room.catastoCategory"/>
          </b-col>
          <b-col>
            <RFInput label="Foglio" v-model="room.catastoFoglio"/>
          </b-col>
          <b-col>
            <RFInput label="Particella" v-model="room.catastoParticella"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Subalterno" v-model="room.catastoSubalterno"/>
          </b-col>
          <b-col>
            <RFCurrencyInput label="Rendita" v-model="room.catastoRendita"/>
          </b-col>
          <b-col>
            <RFInput label="Vani" v-model="room.catastoVani"/>
          </b-col>
        </b-row>

        <b-row class="justify-content-end mt-5">
          <b-col cols="auto">
            <RFButton class="mt-3" label="Save" background="#5CB85C" v-if="!loading" @on-click="save"/>
            <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFCurrencyInput from '@/components/forms/RFCurrencyInput'
import RFButton from '@/components/forms/RFButton'
import RFSelect from '@/components/forms/RFSelect'
import RFLoader from '@/components/forms/RFLoader'
import RFFile from '@/components/forms/RFFile'
import RFInputBeds from '@/components/forms/RFInputBeds'
import RFValidation from '@/components/forms/RFValidation'
import RFCheckbox from '@/components/forms/RFCheckbox'

import AdminImageList from '@/components/admin/AdminImageList'

import { bathroomTypes } from '@/enums'
import { capitalize } from '@/helpers/filters'
import { roomService } from '@/services/admin'
import { mapState } from 'vuex'
import { store } from '@/stores'

export default {
  name: 'AdminAddRoom',
  components: {
    RFInput,
    RFCurrencyInput,
    RFButton,
    RFSelect,
    RFFile,
    RFLoader,
    RFInputBeds,
    RFValidation,
    RFCheckbox,
    AdminImageList,
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.adminBuildingStore.building) {
      next({ name: 'building-list' })
    } else {
      next()
    }
  },
  data() {
    return {
      room: {
        name: null,
        description: null,
        numOfPerson: null,
        floor: null,
        sqMeters: null,
        minStayMonth: null,
        typeId: null,
        numOfBathrooms: null,
        businessUnit: null,
        adminComment: null,
        monthlyAmount: null,
        depositAmount: null,
        serviceAmount: 50.0,
        serviceFees: null,
        bankTransferMonthlyPayments: false,
        ownerContractTemplate:false,
        bathroomType: null,
        beds: {},
        catastoCategory: null,
        catastoFoglio: null,
        catastoParticella: null,
        catastoSubalterno: null,
        catastoRendita: null,
        catastoVani: null,
      },
      accommodationTypeList: [],
      bathroomTypeList: [],
      loading: false,
    }
  },
  computed: {
    ...mapState({
      building: state => state.adminBuildingStore.building,
      accommodationTypes: state => state.accommodationTypeStore.accommodationTypes,
    })
  },
  beforeMount() {
    this.accommodationTypeList = this.accommodationTypes.map(item => ({ value: item.id, label: item.name }))
    Object.keys(bathroomTypes).forEach(key => this.bathroomTypeList.push({ value: key, label: capitalize(key) }))
  },
  beforeDestroy() {
    this.$store.commit('adminBuildingStore/setBuilding', null)
  },
  methods: {
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true
        this.room.minStayMonth = parseInt(this.room.minStayMonth)
        this.room.numOfPerson = parseInt(this.room.numOfPerson)
        this.room.numOfBathrooms = parseInt(this.room.numOfBathrooms)

        const { data: roomId, status } = await roomService.addRoom(this.building.id, this.room)

        if (status === 200) {
          this.$toasted.success(this.$i18n.t(`Room successfully added!`))
          this.$router.push({ name: 'building-list' })
        } else {
          this.$toasted.error(this.$i18n.t(`Error during add room`))
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-room {
  .title {
    @include font-style($montserrat, 'semibold', $font-30);
    color: $dark-blue;
    text-transform: uppercase;
  }
  .subtitle {
    @include font-style($montserrat, 'medium', $font-24);
    color: $dark-blue;
    text-transform: uppercase;
  }
  .room-section {
    margin-top: 40px;

    .header {
      border-bottom: 1px solid $dark-gray;
    }
    .section-title {
      @include font-style($montserrat, 'semibold', $font-22);
      color: $dark-blue;
      text-transform: uppercase;
      margin: 0;
    }
    .add-info-label {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $success;
    }
  }
}
</style>
